<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-3 sticky-top">
        <div class="container">
            <a class="navbar-brand" href="/">
              <img alt="Vue logo" src="../assets/logo.svg" width="150" >
            </a>
            <button class="navbar-toggler bg-navbar" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="navbar-nav ms-auto">
                    <a class="nav-link" :class="{ active: isHomeActive }" aria-current="page" href="/">Home</a>
                    <a class="nav-link" :class="{ active: isAboutActive }" href="/about">About</a>
                    <a class="nav-link" :class="{ active: isMenuActive }" href="/menu">Menu</a>
                    <a class="nav-link" :class="{ active: isContactActive }" href="/contact" tabindex="-1">Contact</a>
                    <h5 class="nav-link call-no">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>                            
                        <span class="ms-1">01922 710300</span>
                    </h5>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
  name: 'my-toolbar',
  components: {},
  data: () => ({
      isHomeActive: false,
      isAboutActive: false,
      isMenuActive: false,
      isContactActive: false,
  }),
  created() {
      this.clearActive()
      switch (this.$route.name) {
          case 'Home': {
            this.isHomeActive = true
            break
          }
          case 'About': {
            this.isAboutActive = true
            break
          }
          case 'Menu': {
            this.isMenuActive = true
            break
          }
          case 'Contact': {
            this.isContactActive = true
            break
          }
      }
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    },
    clearActive() {
        this.isHomeActive = false
        this.isAboutActive = false
        this.isMenuActive = false
        this.isContactActive = false
    }
  }
}
</script>

<style scoped>
.call-no {
  cursor:pointer;
}
.bg-navbar:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}

.bg-logo {
  background-color: #50b670;
}
</style>