<template>
  <div>
    <my-toolbar/>

     <section class="text-light p-5 bg-brand">
      <div class="container">
        <div class="d-flex align-items-center justify-content-center page-header">
          <div> 
            <img class="mb-3 my-pattern" alt="Abduls logo pattern" src="../assets/pattern.svg">
            <h2 class="text-uppercase">Our Menu</h2>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white text-dark p-5 text-sm-start">
      <div class="container">
        <div class="row">
            <div class="col-sm-12">
              <h3 class="">Truly authentic cusine</h3>
              <p>The finest ingredients, imagination and passion of the chefs all combine to craft truly authentic indian cusine, old time classics that weave wonders on the taste buds.</p>
              <a class="btn btn-secondary" href="menu1.pdf" role="button" type="pdf" download target="_blank">Download our menu 1</a>
              <a class="btn btn-secondary" href="menu2.pdf" role="button" type="pdf" download target="_blank">Download our menu 2</a>
            </div>
        </div>
      </div>
    </section>

    <section class="text-dark mb-5">
      <div class="container">
        <div class="accordion">
          <div class="accordion-item" v-for="(item, index) in $options.menuData" :key="item.id">
            <h2 class="accordion-header" :id="'panel-'+item.categoryID">
              <button 
                class="accordion-button bg-accordian" :class="{collapsed: index!=0}" 
                type="button" 
                data-bs-toggle="collapse" 
                :data-bs-target="'#panelsStayOpen-'+item.categoryID" 
                :aria-expanded="(index==0)" 
                :aria-controls="'panelsStayOpen-'+item.categoryID">
                {{ item.name }}
              </button>
            </h2>            
            <div :id="'panelsStayOpen-'+item.categoryID" class="accordion-collapse collapse" :class="{ show: index==0,  }" :aria-labelledby="'panel-'+item.categoryID">
              <div class="accordion-body">
                <p class="my-3">{{ item.description }}</p>
                <div class="row">
                  <div class="col-sm-6" v-for="item in item.items" :key="item.id">
                    <div class="card mb-3">
                      <div class="row align-items-center">
                        <div class="col-sm-8 text-start">
                          <div class="card-body">
                            <h5 class="card-title">{{ item.name }}</h5>
                            <p class="card-text">{{ item.description }}</p>
                            <p class="d-block d-sm-none"><strong>£{{ item.price.toFixed(2) }}</strong></p>
                          </div>
                        </div>
                        <div class="col-sm-4 d-none d-sm-block">
                          <p class="text-end mx-2"><strong>£{{ item.price.toFixed(2) }}</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="alert alert-warning mt-5" role="alert">
            Please note some of our dishes contains nuts, wherever possible we have indicated this with (N) symbol.<br/>If in doubt please ask or contact us for more information <a href="/contact">Contact Us</a>
          </div>

        </div>
      </div>
    </section>



    <my-footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Toolbar from '@/components/Toolbar.vue'
import MyMenu from '../assets/menu.json'

export default {
  name: 'inventory',
  menuData: MyMenu,
  components: {
    'my-footer': Footer,
    'my-toolbar': Toolbar
  },
  data: () => ({
  }),
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.my-nav-link {
  text-decoration: none;
}
.accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}
.accordion-button:not(.collapsed) {
  color:#2F3A31;
}
.bg-accordian {
  background-color: beige;
  font-weight: bold;
}
</style>