<template>
  <div>
    <my-toolbar/>

     <section class="text-light p-5 bg-brand">
      <div class="container">
        <div class="d-flex align-items-center justify-content-center page-header">
          <div> 
            <img class="mb-3 my-pattern" alt="Abduls logo pattern" src="../assets/pattern.svg" width="200">
            <h2 class="text-uppercase">Get in touch</h2>
          </div>
        </div>
      </div>
    </section>

     <section class="bg-white text-dark p-5">
      <div class="container">
        <div class="row">
            <div class="col-sm-12">
              <h4 class="">Opening hours</h4>
              <p class="mt-3">OPEN EVERY DAY <br/><strong>FROM 5.30pm - 11.30pm</strong><br/><span class="text-danger"><strong>(EXCEPT TUESDAY)</strong></span><br/>(inc bank holidays)</p>
              <h4 class="mt-5">Home delivery service</h4>
              <p>Takeaway is available to patrons of our restaurant on request.</p>
              <p><strong>Minimum order £12. £1 per delivery.</strong><br/>There may be delays when we deliver, collection is always recommended.</p>
              <p>As we are unlicenced, please do bring your own drinks with you, but in moderation.</p>
              <img class="my-3" src="https://s3-us-west-1.amazonaws.com/bt-partner-assets/payment-methods.png" alt="Accept Visa, Mastercard, Discover, American Express, and PayPal payments" width="241" height="27"/>
              <p>We welcome all major Credit and Debit Cards Collection and eat in only.</p>
            </div>
        </div>
      </div>
    </section>

    <section class="text-dark">
      <div class="container-fluid">
        <div class="row d-flex align-items-center">
            <div class="col-md-12 bg-grey py-5">
              <h3 class="">Located in Leamore, Walsall</h3>
              <p class="mt-3">Find us: <a href="https://www.google.com/maps/dir//Abduls+Balti+House+Restaurant,+Bloxwich+Road,+Bloxwich,+Walsall/@52.6088079,-2.0309831,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x48709f572129ea57:0x5f4b80f1bc10d066!2m2!1d-1.9958393!2d52.6087774!3e3" target="_blank">Directions from Google</a></p>
              <p class="mt-3">645 Bloxwich Rd<br/>Leamore<br/>Walsall, West Midlands<br/>WS3 2BQ</p>
              <button type="button" class="btn bg-brand text-light">Contact Us</button>
            </div>
            <div class="col-md-12">
              <img class="img-fluid" alt="map" :src="mapUrl">
            </div>
        </div>
      </div>
    </section>

    <my-footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Toolbar from '@/components/Toolbar.vue'

export default {
  name: 'contact',
  components: {
    'my-footer': Footer,
    'my-toolbar': Toolbar
  },
  data: () => ({
    mapUrl: `https://maps.googleapis.com/maps/api/staticmap?
      center=abduls+balti+house
      &zoom=15
      &scale=2&size=600x300
      &maptype=roadmap
      &key=AIzaSyCCYXzQG1o6I20c94EDGa-Gkn-gG40wAx4
      &format=png
      &visual_refresh=true
      &markers=size:mid%7Ccolor:0xce2c2c%7Clabel:1%7Cabduls+balti+house`
  }),
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.bg-grey {
  background-color: #EEEEEE;
}
</style>