<template>
  <div>
    <my-toolbar />
    <section class="bg-brand text-light p-5 text-sm-start">
      <div class="container">
        <div class="d-sm-flex align-items-center justify-center my-5">
          <div>
            <h1 class="mt-3"><span class="text-white">Abdul's</span> Balti House</h1>
            <h6 class="text-uppercase">Authentic Indian restaurant</h6>
            <p class="lead">
              Abdul's Balti House was established in 1993. 
              Our speciality is authentic Indian and Bangladeshi cuisine which is uniquely still served in the traditional balti dishes, accompanied by freshly cooked nan bread and rice.  We pride ourselves on excellent service and flavoursome food.
            </p>
            <a role="button" class="btn btn-lg btn-secondary text-uppercase" href="/menu">View Menu</a>
          </div>
          <img
            class="img-fluid w-50 d-none d-sm-block ms-4"
            alt="Balti logo"
            src="../assets/interior-new.jpeg"/>
        </div>
      </div>
    </section>

    <section class="bg-white text-dark p-5 text-center text-sm-start">
      <div class="container">
        <div class="row text-center">
          <div class="col-sm-12">
            <h2 class="">Welcome to Abdul's Balti House</h2>
            <p>
              We offer our guests an array of starters and traditional curries.<br/>
              A wide range of dishes available in lamb, chicken and seafood. We also offer vegan and vegetarian options.<br/>
              We have a selection of children's dishes making it an ideal place for all the family to dine.
            </p>
          </div>
        </div>

        <div class="row text-center mt-4">
          <div class="col-sm-4">
            <img
              class="img-fluid"
              width="300"
              alt="abduls interior"
              src="../assets/balti-masala.jpeg"
            />
            <h5 class="mt-4">Traditional baltis</h5>
            <p>
              We pride on serving the true athentic balti house experience with rich flavours.
            </p>
          </div>
          <div class="col-sm-4">
            <img
              class="img-fluid"
              width="300"
              alt="abduls interior"
              src="../assets/tandoori.jpeg"
            />
            <h5 class="mt-4">Freshly cooked breads</h5>
            <p>This super-heated technique is what defines tandoori cooking, setting it apart from other cooking styles around the world.</p>
          </div>
          <div class="col-sm-4">
            <img
              class="img-fluid"
              width="300"
              alt="abduls interior"
              src="../assets/balti-curry.jpeg"
            />
            <h5 class="mt-4">Finest ingredients</h5>
            <p>
              We pride ourselves using only fresh produce and ingredients to get the most authentic flavour possible.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-dark text-light p-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center mb-5">
            <img
              class="mb-2 my-pattern"
              alt="Abduls logo pattern"
              src="../assets/pattern.svg"
              width="200"
            />
            <h2 class="">Featured Menu</h2>
            <p>View our House specials</p>
          </div>

          <div
            class="col-sm-6"
            v-for="item in $options.featuredItems"
            :key="item.id"
          >
            <div class="card mb-3 bg-dark">
              <div class="row align-items-center">
                <div class="col-sm-8 text-start">
                  <div class="card-body">
                    <h5 class="card-title">{{ item.name }}</h5>
                    <p class="card-text">{{ item.description }}</p>
                    <p class="d-block d-sm-none">
                      <strong>£{{ item.price.toFixed(2) }}</strong>
                    </p>
                  </div>
                </div>
                <div class="col-sm-4 d-none d-sm-block">
                  <p class="text-end mx-2">
                    <strong>£{{ item.price.toFixed(2) }}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-5 text-dark">
      <div class="container">
        <div class="row">
          <div class="col">

              <div id="carouselExampleCaptions" class="carousel carousel-dark carousel-fade" data-bs-ride="carousel">

                <div class="carousel-indicators">
                  <button type="button" 
                    v-for="(review, index) in $options.reviews" :key="review.id"
                    data-bs-target="#carouselExampleCaptions" 
                    :data-bs-slide-to="index" 
                    :class="{active: index==0}" 
                    :aria-current="index==0" 
                    :aria-label="'Slide ' + index">
                  </button>
                </div>

                <div class="carousel-inner">
                    <div class="carousel-item" :class="{active: index==0}" v-for="(review, index) in $options.reviews" :key="review.id">
                      <img src="../assets/pattern.svg" class="d-block w-100 slider-img" alt="...">                      
                      <div class="carousel-caption">
                        <h2>{{ review.title }}</h2>
                        <blockquote class="blockquote">
                          <p>{{ review.quote }}</p>
                        </blockquote>
                        <figcaption class="blockquote-footer">
                          Someone famous in <cite title="Source Title">{{ review.source }}</cite>
                        </figcaption>
                      </div>
                    </div>
                  
                </div>
                
              </div>
            


          </div>
        </div>
      </div>
    </section>

    <my-footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Toolbar from "@/components/Toolbar.vue";
import featuredMenu from "../assets/featuredMenu.json";
import reviews from "../assets/reviews.json";

export default {
  name: "Home",
  featuredItems: featuredMenu,
  reviews: reviews,
  components: {
    "my-footer": Footer,
    "my-toolbar": Toolbar,
  },
  data: () => ({}),
};
</script>

<style scoped>
.slider-img { 
  opacity: 0.1;
  height: 220px;
}

.b-divider {
  height: 1rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

</style>
