<template>
  <div class="about">
    <my-toolbar/>
    <section class="text-light p-5 bg-brand">
      <div class="container">
        <div class="d-flex align-items-center justify-content-center page-header">
          <div> 
            <img class="mb-3 my-pattern" alt="Abduls logo pattern" src="../assets/pattern.svg" width="200">            
            <h2 class="text-uppercase">About Us</h2>
          </div>
        </div>
      </div>
    </section>
    <section class="text-dark p-5 bg-white">
      <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col text-center">
              <h3 class="">Exceptional quality and service</h3>
              <p>Our speciality is authentic Indian and Bengali cuisine, quality Balti, Tandoori, Biryani and Curry dishes accompanied by freshly cooked nan breads and rice.<br/><br/>Incorporating delicate indian spices to our own recipe's, we uniquely still cook and serve the dishes in the traditional balti dishes.</p>
            </div>
            <div class="col-6">
              <img class="img-fluid" alt="image" src="../assets/interior1.png">
            </div>
        </div>
      </div>
    </section>

    <section class="text-dark p-5 bg-white">
      <div class="container">
        <div class="row d-flex align-items-center flex-row-reverse">
            <div class="col text-center">
              <h3 class="">Traditional Authentic Cuisine</h3>
              <p>Pleasant surroundings, nice, clean, waiter friendly and helpful staff.</p>
            </div>
            <div class="col-6">
              <img class="img-fluid" alt="image" src="../assets/interior2.png">
            </div>
        </div>
      </div>
    </section>

    <my-footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Toolbar from '@/components/Toolbar.vue'

export default {
  name: 'about',
  components: {
    'my-footer': Footer,
    'my-toolbar': Toolbar
  },
  data: () => ({
  }),
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.header-img {
  height: 400px;
  background-image: url('../assets/spices.jpg');
  background-size: cover;
}
</style>