<template>
    <footer class="footer bg-dark mt-auto py-3 text-light">
        <div class="container py-5">
          <div class="row">
            <div class="col">
              <!-- <img alt="abduls logo" src="../assets/logo.svg" width="150"> -->
              <div class="mb-3">
                <a href="https://www.facebook.com/Abduls-Balti-House-1570110079887092/" style="text-decoration:none" target="_blank">              
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-facebook icon-social-fb" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                  </svg>
                </a>
                <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g187059-d3850326-Reviews-Abduls_Balti_House-Walsall_West_Midlands_England.html" style="text-decoration:none" target="_blank">            
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-binoculars-fill ms-3 icon-social-trip" viewBox="0 0 16 16">
                    <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z"/>
                  </svg>
                </a>
              </div>
              <h3 class="mt-4"><span class="">Abdul's</span> Balti House</h3>
              <!-- <p class="mt-3">For any inquiries, please email us on <a href="mailto:abdulsbaltihouse@gmail.com">abdulsbaltihouse@gmail.com</a></p> -->              
              
              <span class="text-muted">Abdul's Balti House © {{ new Date().getFullYear() }}. All rights reserved</span>
          </div>
          </div>
        </div>
        
    </footer>
</template>

<script>
export default {
  name: 'my-footer',
  components: {},
  data: () => ({
  }),
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
.icon-social-fb, .icon-social-trip  {
  color:white;
  opacity: .5;
  transition: all .3s ease;
  -webkit-transition: all .4s ease;
}
.icon-social-fb:hover {
  color: #507CD8;
  cursor: pointer;
  opacity: 1;
}

.icon-social-trip:hover {
  color: #34E0A0;
  cursor: pointer;
  opacity: 1;
}
</style>