<template>
  <router-view/>
</template>

<script>

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js";

export default {

}
</script>

<style lang="scss">

$primary: green;
$secondary: darkgrey;
$accent: red;
$text-heading: #D8CBB3;

@font-face {
  font-family: "Libre";
  src: local("Libre"), url(./fonts/libre_baskerville_bold.otf) format("truetype");
}

@font-face {
  font-family: "Loius bold";
  src: local("Loius bold"), url(./fonts/loiuse-bold.ttf) format("truetype");
}

@font-face {
  font-family: "Loius bold";
  src: local("Loius bold"), url(./fonts/loiuse-bold.ttf) format("truetype");
}

@font-face {
  font-family: "Billgates";
  src: local("Billgates"), url(./fonts/Billgates.ttf) format("truetype");
}
 

h1, h2, h3, h4, h5 {
  font-family: "Libre" !important;
}

// p {
//   font-family: "Loius bold" !important;
// }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Billgates;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg-brand {
  background-color: #2F3A31;
}

.page-header {
  height: 250px
}

.my-pattern {
  opacity: 0.4;
}

h1, h2 {
  color: $text-heading;
}

</style>
